<template>
    <div class="bg-light mt-3 px-md-4 px-3 py-2">
        <div>
            <div>
                <h5>Nuevo tipo de actividad</h5>
            </div>

            <div id="form_output"></div>

            <el-form
                class="col w-100 p-0"
                id="local_form"
                ref="ruleForm"
                :rules="rules"
                :model="activityType"
                @submit.prevent="submitForm('ruleForm')"
                status-icon
            >
                <div class="row w-100 d-flex justify-content-between mx-0">
                    <el-form-item class="col pl-0" prop="name" id="name">
                        <el-input
                            class="w-100"
                            v-model="activityType.name"
                            placeholder="Ingrese el tipo de actividad"
                        />
                    </el-form-item>

                    <div>
                        <button
                            size="small"
                            @click.prevent="submitForm('ruleForm')"
                            class="btn btn-info text-white"
                        >
                            {{ isEdition ? "Actualizar" : "Guardar" }}
                        </button>
                    </div>
                </div>
            </el-form>

            <!-- ---------------------------------------------------------------------------------- -->

            <el-table
                :data="activitiesTable"
                style="width: 100%; height: 100%"
                max-height="500"
                v-loading="waiting"
            >
                <el-table-column prop="id" label="Cód" width="90">
                </el-table-column>

                <el-table-column
                    sortable
                    prop="name"
                    min-width="250%"
                    label="Tipo de Actividad"
                >
                </el-table-column>

                <el-table-column
                    min-width="200%"
                    label="Estado"
                    :filters="[
                        { text: 'Activo', value: 1 },
                        { text: 'Inactivo', value: 0 },
                    ]"
                    :filter-method="filterState"
                >
                    <template slot-scope="scope">
                        <el-switch
                            class="align-items-center switch-compact switch"
                            @change="editActivityState(scope.$index)"
                            :value="scope.row.active == 1"
                            active-text="Activo"
                            inactive-text="Inactivo"
                        >
                        </el-switch>
                    </template>
                </el-table-column>

                <el-table-column
                    class-name="item-center"
                    fixed="right"
                    label="Acciones"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="small"
                            @click="editActivityType(scope.$index)"
                            :type="
                                isEdition && scope.$index == index
                                    ? 'warning'
                                    : 'primary'
                            "
                            :icon="
                                isEdition && scope.$index == index
                                    ? 'el-icon-close'
                                    : 'el-icon-edit'
                            "
                        />
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    props: ["activities"],
    data() {
        return {
            activitiesTable: [...this.activities],
            activityType: {
                name: undefined,
                active: true,
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: "Debe escribir el tipo de la denuncia",
                        trigger: ["change", "blur"],
                    },
                ],
            },
            index: -1,
            editedActivityTypeId: undefined,
            isEdition: false,
            waiting: false,
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid, rules) => {
                if (valid) {
                    this.submit();
                } else {
                    this.goToError(rules);
                }
            });
        },
        clearForm() {
            this.activityType = {
                name: undefined,
            };
            this.$refs["ruleForm"].resetFields();
        },
        clearEditData() {
            this.index = -1;
            this.isEdition = false;
            this.editedActivityTypeId = undefined;
        },
        filterState(value, row) {
            return row.active == value;
        },
        editActivityType(activityIndex) {
            if (this.index == activityIndex) {
                this.clearEditData();
                this.clearForm();
                return;
            }
            const activityType = this.activities[activityIndex];
            const { id, name, active } = activityType;
            this.isEdition = true;
            this.editedActivityTypeId = id;
            this.index = activityIndex;
            this.activityType = {
                name: name,
                active: active,
            };
        },
        editActivityState(activityIndex) {
            const activityType = this.activities[activityIndex];
            const { id, name, active } = activityType;
            this.editedActivityTypeId = id;
            this.index = activityIndex;
            const data = {
                name: name,
                active: active == 1 ? 0 : 1,
            };
            this.submitEdit(data);
        },
        submit() {
            if (this.isEdition) {
                const { name, active } = this.activityType;
                const data = {
                    name: name,
                    active: active,
                };
                this.submitEdit(data);
            } else {
                this.submitCreate();
            }
        },
        submitCreate() {
            this.waiting = true;
            const url = `${window.location.origin}/activity-types`;
            axios
                .post(url, this.activityType)
                .then((res) => {
                    this.waiting = false;
                    this.activitiesTable.push(res.data.newActivityType);

                    this.clearForm();
                    this.$message({
                        message: res.data.message,
                        type: "success",
                    });
                })
                .catch((error) => {
                    this.waiting = false;
                    console.error(error);
                    this.$message.error(
                        "Ha ocurrido un error al crear el tipo de infracción"
                    );
                });
        },
        submitEdit(activityTypeData) {
            this.waiting = true;
            const url = `${window.location.origin}/activity-types/${this.editedActivityTypeId}`;
            axios
                .put(url, activityTypeData)
                .then((res) => {
                    this.waiting = false;
                    let activities = this.activitiesTable.map((row) => {
                        if (row.id === this.editedActivityTypeId) {
                            return res.data.activityType;
                        }
                        return row;
                    });
                    this.activitiesTable = this.copy(activities);

                    this.clearForm();
                    this.clearEditData();
                    this.$message({
                        message: res.data.message,
                        type: "success",
                    });
                })
                .catch((error) => {
                    this.waiting = false;
                    console.error(error);
                    this.$message.error(
                        "Ha ocurrido un error al editar el tipo de infracción"
                    );
                });
        },
    },
};
</script>
