<template>
    <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
    >
        <div class="text-left mt-5 mb-3">
            <p>
                Antes de empezar, por favor leer cuidadosamente la política de
                privacidad.
            </p>

            <div class="form-group">
                <el-form-item prop="consent" id="consent">
                    <el-checkbox v-model="ruleForm.consent">
                        Estoy de acuerdo con la
                        <a
                            href="pdf/Política-de-Privacidad-VE.pdf"
                            target="_blank"
                        >
                            Política de privacidad
                        </a>
                        .
                    </el-checkbox>
                </el-form-item>
            </div>
        </div>

        <hr />

        <h5 class="text-left">Por favor completar la siguiente información:</h5>

        <el-form-item label="Qué acción quieres realizar:" class="w-100">
            <el-radio v-model="ruleForm.suggestion" label="0">
                Incidente
            </el-radio>

            <el-radio v-model="ruleForm.suggestion" label="1">
                Sugerencia
            </el-radio>
        </el-form-item>

        <el-form-item
            v-show="ruleForm.suggestion == 0"
            label="Tipo de denuncia:"
            class="w-100"
            prop="complaints_type_id"
            id="complaints_type_id"
        >
            <el-select
                v-model="ruleForm.complaints_type_id"
                class="w-100"
                placeholder="Selecciona el tipo de denuncia"
                filterable
                @change="validateComplaintType"
            >
                <el-option
                    v-for="complaint_type in complaints_types"
                    :key="complaint_type.id"
                    :label="complaint_type.type"
                    :value="complaint_type.id"
                >
                    <el-popover
                        placement="left"
                        title="¿Qué significa esto?"
                        width="250"
                        :disabled="handlePopover(complaint_type.description)"
                        trigger="hover"
                        :content="complaint_type.description"
                    >
                        <div slot="reference">{{ complaint_type.type }}</div>
                    </el-popover>
                </el-option>
            </el-select>
        </el-form-item>

        <el-form-item
            label="Entidad donde ocurrió:"
            class="w-100"
            prop="country_id"
            id="country_id"
        >
            <!-- país donde ocurrió-->

            <el-select
                v-model="ruleForm.country_id"
                class="w-100"
                placeholder="Selecciona el país en donde sucedió"
                filterable
            >
                <el-option
                    v-for="country in countries"
                    :key="country.id"
                    :label="country.name"
                    :value="country.id"
                >
                </el-option>
            </el-select>
        </el-form-item>

        <el-form-item
            v-show="ruleForm.suggestion == 0 || !this.allowAnonymous"
            label="¿Es una denuncia anónima?"
            class="w-100"
        >
            <!-- es denuncia anónima? -->

            <el-radio v-model="ruleForm.anonymous" :disabled="isKarin" label="0"
                >No</el-radio
            >

            <el-radio v-model="ruleForm.anonymous" :disabled="isKarin" label="1"
                >Si</el-radio
            >
            <i
                v-show="isKarin"
                class="el-icon-warning-outline mr-1 text-red"
                color="#409efc"
                v-popover:fromPopOver
            />
            <el-popover
                ref="fromPopOver"
                placement="left"
                title="IMPORTANTE"
                width="250"
                trigger="hover"
                content="De acuerdo con la Ley 21.643 - Ley Karin, las denuncias categorizadas bajo esta ley deben incluir la identificación del denunciante (nombre, RUT y correo electrónico) para garantizar la integridad del proceso e investigación con la protección adecuada de todas las partes. Su información será tratada con la máxima confidencialidad y resguardo."
            />
        </el-form-item>

        <div v-show="ruleForm.anonymous == 0 || isKarin">
            <el-form-item
                label="Nombre completo:"
                class="w-100"
                prop="contact_name"
                id="contact_name"
            >
                <!-- name -->

                <el-input
                    placeholder="Por favor escribe tu nombre:"
                    v-model="ruleForm.contact_name"
                ></el-input>
            </el-form-item>

            <el-form-item
                label="Número de documento o CI:"
                class="w-100"
                prop="document_id"
                id="document_id"
            >
                <!-- document_id -->

                <el-input
                    placeholder="Por favor escribe tu número de documento"
                    v-model="ruleForm.document_id"
                ></el-input>
            </el-form-item>

            <el-form-item
                type="tel"
                label="Teléfono:"
                class="w-100"
                prop="contact_phone"
                id="contact_phone"
                pattern="/^\(?(\d{3})\)?[-]?(\d{3})[-]?(\d{4})$/"
            >
                <!-- telephone -->

                <el-input
                    placeholder="Por favor escribe tu teléfono"
                    v-model="ruleForm.contact_phone"
                ></el-input>
            </el-form-item>
            <el-form-item
                label="Email:"
                class="w-100"
                prop="contact_email"
                id="contact_email"
            >
                <!-- email -->
                <el-input
                    placeholder="Por favor escribe tu correo electrónico"
                    v-model="ruleForm.contact_email"
                ></el-input>
            </el-form-item>
            <el-form-item
                label="Prefieres que nos comuniquemos contigo por:"
                class="w-100"
            >
                <!-- contact preference -->

                <el-radio v-model="ruleForm.contact_by_email" label="1">
                    Email
                </el-radio>

                <el-radio v-model="ruleForm.contact_by_email" label="2">
                    Teléfono
                </el-radio>
            </el-form-item>

            <el-form-item
                id="character_id"
                label="Te identificas como:"
                class="w-100"
                prop="character_id"
            >
                <!-- identification -->

                <el-select
                    v-model="ruleForm.character_id"
                    class="w-100"
                    placeholder="Te identificas como:"
                    filterable
                >
                    <el-option
                        v-for="person in people"
                        :key="person.id"
                        :label="person.name"
                        :value="person.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item
                label="La denuncia o sospecha lo afecta directamente:"
                class="w-100"
            >
                <!-- affects it directly -->

                <el-radio v-model="ruleForm.affects_it_directly" label="0">
                    No
                </el-radio>

                <el-radio v-model="ruleForm.affects_it_directly" label="1">
                    Si
                </el-radio>
            </el-form-item>
        </div>

        <div v-show="ruleForm.anonymous == 1 || ruleForm.suggestion == 1">
            <el-form-item
                label="Email (opcional):"
                class="w-100"
                prop="contact_email"
                id="contact_email"
            >
                <!-- email -->

                <el-input
                    placeholder="Por favor escribe tu correo electrónico"
                    v-model="ruleForm.contact_email"
                ></el-input>
            </el-form-item>
        </div>

        <div
            v-show="
                (this.allowAnonymous && ruleForm.suggestion == 0) ||
                (!this.allowAnonymous && ruleForm.suggestion == 1)
            "
        ></div>

        <hr />

        <el-row type="flex" justify="end">
            <el-button
                class="ml-auto"
                v-if="!waiting"
                type="primary"
                @click.prevent="submitForm('ruleForm')"
            >
                Siguiente
            </el-button>

            <el-button
                class="ml-auto"
                v-if="waiting"
                type="primary"
                :loading="true"
            >
                Guardando...
            </el-button>
        </el-row>
    </el-form>
</template>

<script>
export default {
    props: [
        "next",
        "setId",
        "setStepData",
        "complaints_types",
        "people",
        "countries",
        "oldData",
    ],
    data() {
        var checkConsent = (rule, value, callback) => {
            if (!value) {
                return callback(
                    new Error("Debe dar su consentimiento antes de continuar.")
                );
            }
            return callback();
        };
        var checkComplaintData = (rule, value, callback, source, options) => {
            const isSuggestion = this.ruleForm.suggestion;
            if (isSuggestion === "0") {
                if (value === "") {
                    return callback(
                        new Error("Debe informar el tipo de denuncia")
                    );
                }
            }
            return callback();
        };
        var checkCountry = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("Debe seleccionar país / entidad."));
            }
            return callback();
        };
        var checkAnonymous = (rule, value, callback) => {
            const isAnonymous = this.ruleForm.anonymous;
            if (
                rule.field !== "contact_phone" &&
                rule.field !== "contact_email" &&
                rule.field !== "document_id"
            ) {
                var name = "";
                switch (rule.field) {
                    case "contact_name":
                        name = "nombre completo";
                        break;
                    case "character_id":
                        name = "cuál es su perfil";
                        break;
                    default:
                        name = "";
                        break;
                }

                if (!value && isAnonymous === "0") {
                    return callback(new Error("Debe informar " + name));
                }
            } else {
                const contact_by_email = this.ruleForm.contact_by_email;
                if (rule.field === "contact_email" && value != "") {
                    if (!this.isEmailValid(value)) {
                        return callback(
                            new Error("Formato de email incorrecto")
                        );
                    }
                }
                if (isAnonymous === "0") {
                    if (value === "") {
                        if (rule.field === "document_id") {
                            return callback(new Error("Debe informar el rut"));
                        }
                        if (
                            rule.field === "contact_phone" &&
                            contact_by_email === "2"
                        ) {
                            return callback(
                                new Error("Debe informar el número de teléfono")
                            );
                        }
                        if (
                            rule.field === "contact_email" &&
                            contact_by_email === "1"
                        ) {
                            return callback(
                                new Error("Debe informar el email")
                            );
                        }
                    } else {
                        if (
                            rule.field === "document_id" &&
                            !this.validaRut(value)
                        ) {
                            return callback(
                                new Error("Formato de rut incorrecto")
                            );
                        }
                        if (
                            rule.field === "contact_phone" &&
                            !this.validaPhone(value)
                        ) {
                            return callback(
                                new Error("Formato de telefono incorrecto")
                            );
                        }
                    }
                }
            }
            return callback();
        };

        return {
            allowAnonymous: true,
            isKarin: false,
            waiting: false,
            error: null,
            ruleForm: {
                consent: this.$props.oldData.consent
                    ? this.$props.oldData.consent
                    : false,
                complaints_type_id: this.$props.oldData.complaints_type_id
                    ? this.$props.oldData.complaints_type_id
                    : "",
                country_id: this.$props.oldData.country_id
                    ? this.$props.oldData.country_id
                    : "",
                character_id: this.$props.oldData.character_id
                    ? this.$props.oldData.character_id
                    : "",
                contact_email: this.$props.oldData.contact_email
                    ? this.$props.oldData.contact_email
                    : "",
                contact_name: this.$props.oldData.contact_name
                    ? this.$props.oldData.contact_name
                    : "",
                document_id: this.$props.oldData.document_id
                    ? this.$props.oldData.document_id
                    : "",
                contact_phone: this.$props.oldData.contact_phone
                    ? this.$props.oldData.contact_phone
                    : "",
                contact_by_email: this.$props.oldData.contact_by_email
                    ? this.$props.oldData.contact_by_email
                    : "1",
                affects_it_directly: this.$props.oldData.affects_it_directly
                    ? this.$props.oldData.affects_it_directly
                    : "0",
                suggestion: this.$props.oldData.suggestion
                    ? this.$props.oldData.suggestion
                    : "0",
                anonymous: this.$props.oldData.anonymous
                    ? this.$props.oldData.anonymous
                    : "0",
            },
            rules: {
                consent: [{ validator: checkConsent, trigger: "change" }],
                complaints_type_id: [
                    { validator: checkComplaintData, trigger: "change" },
                ],
                country_id: [{ validator: checkCountry, trigger: "change" }],
                contact_name: [
                    { validator: checkAnonymous, trigger: ["change", "blur"] },
                ],
                document_id: [
                    { validator: checkAnonymous, trigger: ["change", "blur"] },
                ],
                contact_phone: [
                    { validator: checkAnonymous, trigger: ["change", "blur"] },
                ],
                contact_email: [
                    { validator: checkAnonymous, trigger: ["change", "blur"] },
                ],
                character_id: [
                    { validator: checkAnonymous, trigger: ["change", "blur"] },
                ],
            },
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid, rules) => {
                if (valid) {
                    if (this.isKarin) {
                        this.$confirm(
                            "Estás a punto de realizar una denuncia que, para cumplir con los requisitos de la Ley Karin, requiere que te identifiques según lo estipulado en el artículo 11 de la ley. Además, la denuncia deberá ser ratificada por escrito al finalizar este proceso inicial. Puedes presentar esta denuncia en representación de otra persona o si te afecta directamente.",
                            "Alerta",
                            {
                                confirmButtonText: "Continuar",
                                cancelButtonText: "Volver",
                                type: "error",
                                customClass: "anonymousAlert",
                                showConfirmButton: this.isKarin,
                            }
                        ).then(() => {
                            this.handleSubmit();
                        });
                    } else {
                        if (
                            this.ruleForm.suggestion == "0" &&
                            this.ruleForm.anonymous == "1"
                        ) {
                            this.$confirm(
                                "Al realizar tu denuncia de manera anónima, no podremos contactarte para abrir una investigación o para comunicarte sus avances, pero tu denuncia si será revisada y quedará el registro correspondiente.",
                                "Alerta",
                                {
                                    confirmButtonText: "Continuar",
                                    cancelButtonText: "Volver",
                                    type: "warning",
                                    customClass: "anonymousAlert",
                                    showCancelButton: true,
                                }
                            ).then(() => {
                                this.handleSubmit();
                            });
                        } else {
                            this.handleSubmit();
                        }
                    }
                } else {
                    this.goToError(rules, "consent");
                }
            });
        },
        openConsentText() {
            this.$alert(this.consentText, "Política de privaconcidad", {
                dangerouslyUseHTMLString: true,
            });
        },
        handleSubmit() {
            this.waiting = true;
            const url = `${window.location.origin}/complaint/postdata1`;
            axios
                .post(url, this.ruleForm)
                .then((res) => {
                    this.waiting = false;
                    if (res.data.success) {
                        this.$props.setId(res.data.id, res.data.complaint);
                        this.$props.setStepData(this.ruleForm);
                        if (this.ruleForm.suggestion == "1") {
                            this.$props.next("a");
                        } else {
                            this.$props.next("b");
                        }
                    }
                })
                .catch((e) => {
                    this.waiting = false;
                });
        },
        handlePopover(description) {
            return !this.truthty(description);
        },
        validateComplaintType(complaintsId) {
            const selectedComplaintType = this.$props.complaints_types.find(
                (row) => row.id === complaintsId
            );
            this.isKarin = selectedComplaintType.is_karin;
            this.allowAnonymous = selectedComplaintType.anonymous;
            if (selectedComplaintType.is_karin) {
                this.ruleForm.anonymous = "0";
            }
        },
    },
};
</script>
